// 그룹 서비스
import {Injectable} from "@angular/core";
import {LocalApiService} from "./local-api.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {GroupModel} from "../_models/group.model";
import {ListResponseModel} from "../_models/list-response.model";

@Injectable()
export class GroupService {

  constructor(private api: LocalApiService, private https: HttpClient) { /* http -> https */
  }

  /**
   * 그룹 조회 api
   */
  readGroups(page = 0, rpp = 50): Promise<ListResponseModel<GroupModel>> {
    const params = new HttpParams()
      .set('type', 'all');

    return this.https.get<ListResponseModel<GroupModel>>(`${this.api.defaultApiAddress}/groups`, {headers: this.api.defaultHeaders, params: params}).toPromise();
  }

  /**
   * 그룹 등록 api
   */
  createGroups(groups: GroupModel):  Promise<GroupModel> {
    return this.https.post<GroupModel>(`${this.api.defaultApiAddress}/groups`, groups, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 그룹 ID로 수정 api
   */
  updateGroupsById(groupId: string, groups: any): Promise<GroupModel> {
    return this.https.put<GroupModel>(`${this.api.defaultApiAddress}/groups/${groupId}`, groups, {headers: this.api.defaultHeaders}).toPromise();
  }

}
