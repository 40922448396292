// 보물 서비스
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LocalApiService} from './local-api.service';
import {ClueContentModel} from '../_models/clue-content.model';
import {TreasureModel} from '../_models/treasure.model';
import {ListResponseModel} from '../_models/list-response.model';
import {AngularFireAuth} from 'angularfire2/auth';
import {ClueModel} from '../_models/clue.model';

@Injectable()
export class TreasureService {

    constructor(private api: LocalApiService, private https: HttpClient, private afAuth: AngularFireAuth) {
    }

    /**
     * 보물 추가 api
     */
    createTreasure(eventId: string, treasures: TreasureModel): Promise<TreasureModel> {
        return this.https.post<TreasureModel>(`${this.api.defaultApiAddress}/events/${eventId}/treasures`, treasures, {
            headers: this.api.defaultHeaders,
        }).toPromise();
    }

    /**
     * 보물 조회 api
     */
    readTreasure(eventId: string): Promise<ListResponseModel<TreasureModel>> {
        return this.https.get<ListResponseModel<TreasureModel>>(`${this.api.defaultApiAddress}/events/${eventId}/treasures`, {
            headers: this.api.defaultHeaders
        }).toPromise();
    }

    /**
     * 보물 ID로 조회 api
     */
    readTreasureById(id: string): Promise<TreasureModel> {
        return this.https.get<TreasureModel>(`${this.api.defaultApiAddress}/treasures/${id}`, {
            headers: this.api.defaultHeaders,
        }).toPromise();
    }

    /**
     * 보물 ID로 수정 api
     */
    updateTreasureById(id: string, treasures: any): Promise<TreasureModel> {

        let params = new HttpParams();
        params = params.append('type', 'all');

        return this.https.put<TreasureModel>(`${this.api.defaultApiAddress}/treasures/${id}`, treasures, {
            headers: this.api.defaultHeaders,
            params: params
        }).toPromise();
    }

    /**
     * 보물 ID로 삭제 api
     */
    deleteTreasureById(id: string): Promise<TreasureModel> {
        return this.https.delete<TreasureModel>(`${this.api.defaultApiAddress}/treasures/${id}`, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 단서 추가 api
     */
    createClues(id: string, clues: any): Promise<ClueContentModel> {
        return this.https.post<ClueContentModel>(`${this.api.defaultApiAddress}/treasures/${id}/clues`, clues, {headers: this.api.defaultHeaders}).toPromise();
    }


    /**
     * 단서 조회 api
     */

    readClues(page = 0, rpp = 50, id: string): Promise<ListResponseModel<ClueModel>> {
        return this.https.get<ListResponseModel<ClueModel>>(`${this.api.defaultApiAddress}/treasures/${id}/clues`, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 단서 ID로 조회 api
     */
    readCluesById(id: string): Promise<ClueModel> {
        return this.https.get<ClueModel>(`${this.api.defaultApiAddress}/clues/${id}`, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * AR파일에 미션 저장
     */
    createAR(id: string, arUrl: any): Promise<any> {
        const defaultHeaders = new HttpHeaders();
        this.afAuth.auth.currentUser.getIdToken(true).then(token => {
            defaultHeaders.append('Authorization', token);
            defaultHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        });

        /**
         * 2019.07.09 수정
         * return this.https.post<any>(`${this.api.defaultApiAddress}/treasures/${id}/ar`, arUrl, {headers: defaultHeaders}).toPromise();
         * ar -> attitude
         */
        return this.https.post<any>(`${this.api.defaultApiAddress}/treasures/${id}/ar/wikitude`, arUrl, {headers: defaultHeaders}).toPromise();
    }

    /**
     * 단서 content ID로 수정하는 api clue: ClueModel
     */
    updateClueById(clueId: string, clue: any): Promise<ClueContentModel> {
        return this.https.put<ClueContentModel>(`${this.api.defaultApiAddress}/clues/${clueId}`, clue, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 단서 content ID로 삭제하는 api
     */
    deleteClueById(clueId: string): Promise<ClueContentModel> {
        return this.https.delete<ClueContentModel>(`${this.api.defaultApiAddress}/clues/${clueId}`, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 삭제된 단서 강제 업데이트
     */
    updateClueByIdForce(clueId: string, clue: any): Promise<ClueContentModel> {
        let params = new HttpParams();
        params = params.append('restore', 'true');

        return this.https.put<ClueContentModel>(`${this.api.defaultApiAddress}/clues/${clueId}`, clue, {headers: this.api.defaultHeaders, params: params}).toPromise();
    }

    /**
     * 삭제된 단서 조회
     */
    readShowClues(): Promise<ClueModel> {
        let params = new HttpParams();
        params = params.append('showDeleted', 'true');

        return this.https.get<ClueModel>(`${this.api.defaultApiAddress}/clues`, {headers: this.api.defaultHeaders, params: params}).toPromise();
    }
}
