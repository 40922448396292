import {RolesModel} from "./roles.model";
import {GroupsModel} from "./groups.model";

export class UserModel {
  id: string;
  email: string;
  isEmailVerified: boolean;
  username: string;
  photoUrl: any;
  status: string;
  currentAccessDateTime: string;
  createdAt: string;
  updatedAt: string;
  roles: RolesModel[] = [];
  file: File;
  groups: GroupsModel[] = [];
  deviceId: string;
}
