// 보물 모델
import {MultiLanguageModel} from "./multiLanguage.model";
import {LocationModel} from "./location.model";
import {MediaModel} from "./media.model";
import {ReceiptSpotModel} from './receiptSpot.model';
import {TreasureProductModel} from './treasure-product.model';

export class TreasureModel {
  id: string; // 보물 고유 ID
  arObjectUrl: string; // 보물 AR처리용 파일 URL주소
  location: LocationModel;
  radius: number; // 보물 AR 활성 반경, 단위 : 미터
  limitDiscoverClueCount: number; // 보물 발견에 필요한 최고 단서 Unlock 개수
  type: string;
  createdAt: string;
  file: string;
  eventId: string;
  wtcFile: string;
  seq: number;
  arObjectFileName: string;
  treasureProducts: TreasureProductModel[] = [];
}
