// 보물 상품 서비스
import {Injectable} from '@angular/core';
import {LocalApiService} from './local-api.service';
import {HttpClient} from '@angular/common/http';
import {AngularFireAuth} from 'angularfire2/auth';
import {TreasureProductModel} from '../_models/treasure-product.model';
import {ListResponseModel} from '../_models/list-response.model';

@Injectable()
export class TreasureProductService {

    constructor(private api: LocalApiService, private https: HttpClient, private afAuth: AngularFireAuth) {
    }

    /**
     * 보물 상품 등록 api
     */
    createTreasureProduct(treasureId: string, product: TreasureProductModel): Promise<TreasureProductModel> {
        return this.https.post<TreasureProductModel>(`${this.api.defaultApiAddress}/treasure/${treasureId}/treasure-products`, product, {
            headers: this.api.defaultHeaders,
        }).toPromise();
    }

  /**
   * 보물상품 조회 All api by Sarc
   */
  readTreasureProductAll(): Promise<ListResponseModel<TreasureProductModel>> {
    return this.https.get<ListResponseModel<TreasureProductModel>>(`${this.api.defaultApiAddress}/treasure-products`, {
      headers: this.api.defaultHeaders
    }).toPromise();
  }

    /**
     * 보물상품 조회 api
     */
    readTreasureProduct(treasureId: string): Promise<ListResponseModel<TreasureProductModel>> {
        return this.https.get<ListResponseModel<TreasureProductModel>>(`${this.api.defaultApiAddress}/treasures/${treasureId}/treasure-products`, {
            headers: this.api.defaultHeaders
        }).toPromise();
    }

    /**
     * 보물상품 ID로 조회 api
     */
    readTreasureProductById(treasureProductId: string): Promise<TreasureProductModel> {
        return this.https.get<TreasureProductModel>(`${this.api.defaultApiAddress}/treasure-products/${treasureProductId}`, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 보물상품 수정 api
     */
    updateTreasureProductById(treasureProductId: string, product: any): Promise<TreasureProductModel> {
        return this.https.put<TreasureProductModel>(`${this.api.defaultApiAddress}/treasure-products/${treasureProductId}`, product, {headers: this.api.defaultHeaders}).toPromise();
    }

    /**
     * 보물 상품 ID로 삭제 api
     */
    deleteTreasureProductById(treasureId: string, treasureProductId: string): Promise<TreasureProductModel> {
        return this.https.delete<TreasureProductModel>(`${this.api.defaultApiAddress}/treasures/${treasureId}/treasure-products/${treasureProductId}`, {headers: this.api.defaultHeaders}).toPromise();
    }

}
